import { IEnvironmentSettings } from "./model";
import { version } from './../../package.json'

export const env: IEnvironmentSettings = {
    name: "dev",
    production: false,
    appVersion: `${version}-dev`,
    apiBaseUrl: 'https://sandbox.nexusforge.app',
    auth: {
        accessTokenKey: 'DoPS3ZrQjM',
        refreshTokenKey: 'nmlP8PW2nb',
        applicationId: "demoappkey"
    },
    formsAppUrl: "http://localhost:4200",
    s3Config: {
        accessKey: "AKIAVSRRMWECLRR6T5NW",
        secretKey: "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",
        region: 'ap-south-1',
        bucket: "nexus-forge-dev"
    }
}
